import type { Writable } from 'svelte/store';
import { writable } from 'svelte/store';
import type { BookInfo, SimpleGameInfo } from 'common/info';
import type {
  AchievementDetail,
  AddMonstersPage,
  AttackModifiersPage,
  BattleGoalsPage,
  BrowseAbilityCardDetail,
  BrowseMobDetail,
  BrowseScenarioDetail,
  BrowseSimpleDetail,
  BuildingsPage,
  CampaignDetail,
  CampaignSheetPage,
  ChallengesPage,
  CharacterAbilityPage,
  CharacterPage,
  CharactersLayout,
  CharactersPage,
  ChooseScenarioPage,
  DifficultyPage,
  EditPageSectionsPage,
  EditScenarioPage,
  EditInfoPage,
  EndScenarioPage,
  EnhancePage,
  EventDeckPage,
  EventPage,
  HelpPage,
  ImageDetail,
  JournalPage,
  LineagePage,
  LootCardsPage,
  NewCharacterPage,
  PartyGoalsPage,
  PetsPage,
  PlayLogsPage,
  PlayPage,
  ProsperityPage,
  RandomItemsPage,
  RandomScenariosPage,
  RetiredPage,
  SanctuaryPage,
  SandboxPage,
  ScenarioDetailPage,
  ScenarioPagesPage,
  ScenariosPage,
  SectionPage,
  SettingsPage,
  ShopItemsPage,
  SpecialConditionDetail,
  TownRecordsPage,
  TreasureIndexPage,
  UnlockBuildingsPage,
  UnlockClassesPage,
  UnlockGoalsPage,
  UnlockItemsPage,
  UnlockQuestsPage,
  WorldMapPage,
} from 'common/render';
import { default_page_data } from 'v2/render';
import { local_get, local_set } from 'v2/util';

export const restarting = writable<boolean>(false);

export function set_restarting(on: boolean) {
  restarting.update(() => on);
}

export interface AlertArgs {
  text: string;
  ms: number;
}

export const alert = writable<AlertArgs[]>([]);
export const map_modal = writable('');

export function set_alert(text: string, ms?: number) {
  alert.update((old) => (old ?? []).concat([{ text, ms: ms ?? 2500 }]).slice(-2));
}

export function clear_alert() {
  alert.update((old) => []);
}

export const current_log_pages = writable(local_get('current_log_pages', 'pages'));

// Maintain copies of sveltekit's page data so we can more accurately handle
// caching and invalidating. In fact, we never use sveltekits copy because we
// always directly handle the page data in every +page.ts.
export const campaign = writable<CampaignDetail>({
  anon_uuid: '',
  campaign_uuid: '',
  name: '',
  game_id: 1,
  scenario_id: 0,
  prosperity_level: 1,
});
export const auto_center_id = writable(0);
export const draw_audio_id = writable(0);
export const transaction_id = writable(0);
export const enabled_games = writable<SimpleGameInfo[]>(null);
export const characters_layout: Writable<CharactersLayout> = writable(
  default_page_data.characters_layout
);
export const image_detail: Writable<ImageDetail> = writable(
  default_page_data.image_detail
);
export const play_page: Writable<PlayPage> = writable(default_page_data.play_page);
export const play_logs_page: Writable<PlayLogsPage> = writable(
  default_page_data.play_logs_page
);
export const sandbox_page: Writable<SandboxPage> = writable(
  default_page_data.sandbox_page
);
export const scenario_page: Writable<ScenariosPage> = writable(
  default_page_data.scenario_page
);
export const solo_scenario_page: Writable<ScenariosPage> = writable(
  default_page_data.solo_scenario_page
);
export const scenario_detail_page: Writable<ScenarioDetailPage> = writable(
  default_page_data.scenario_detail_page
);
export const prosperity_page: Writable<ProsperityPage> = writable(
  default_page_data.prosperity_page
);
export const global_achievements: Writable<AchievementDetail[]> = writable(
  default_page_data.global_achievements
);
export const party_achievements: Writable<AchievementDetail[]> = writable(
  default_page_data.party_achievements
);
export const reputation: Writable<number> = writable(default_page_data.reputation);
export const special_condition_details: Writable<SpecialConditionDetail[]> = writable(
  default_page_data.special_condition_details
);
export const party_goals_page: Writable<PartyGoalsPage> = writable(
  default_page_data.party_goals_page
);
export const sanctuary_page: Writable<SanctuaryPage> = writable(
  default_page_data.sanctuary_page
);
export const town_records_page: Writable<TownRecordsPage> = writable(
  default_page_data.town_records_page
);
export const characters_page: Writable<CharactersPage> = writable(
  default_page_data.characters_page
);
export const retired_page: Writable<RetiredPage> = writable(
  default_page_data.retired_page
);
export const character_page: Writable<CharacterPage> = writable(
  default_page_data.character_page
);
export const enhance_page: Writable<EnhancePage> = writable(
  default_page_data.enhance_page
);
export const new_character_page: Writable<NewCharacterPage> = writable(
  default_page_data.new_character_page
);
export const lineage_page: Writable<LineagePage> = writable(
  default_page_data.lineage_page
);
export const shop_items_page: Writable<ShopItemsPage> = writable(
  default_page_data.shop_items_page
);
export const unlock_items_page: Writable<UnlockItemsPage> = writable(
  default_page_data.unlock_items_page
);
export const unlock_quests_page: Writable<UnlockQuestsPage> = writable(
  default_page_data.unlock_quests_page
);
export const unlock_goals_page: Writable<UnlockGoalsPage> = writable(
  default_page_data.unlock_goals_page
);
export const journal_page: Writable<JournalPage> = writable(
  default_page_data.journal_page
);
export const event_page: Writable<EventPage> = writable(default_page_data.event_page);
export const event_deck_page: Writable<EventDeckPage> = writable(
  default_page_data.event_deck_page
);
export const random_scenarios_page: Writable<RandomScenariosPage> = writable(
  default_page_data.random_scenarios_page
);
export const random_items_page: Writable<RandomItemsPage> = writable(
  default_page_data.random_items_page
);
export const battle_goals_page: Writable<BattleGoalsPage> = writable(
  default_page_data.battle_goals_page
);
export const challenges_page: Writable<ChallengesPage> = writable(
  default_page_data.challenges_page
);
export const pets_page: Writable<PetsPage> = writable(default_page_data.pets_page);
export const world_map_page: Writable<WorldMapPage> = writable(
  default_page_data.world_map_page
);
export const difficulty_page: Writable<DifficultyPage> = writable(
  default_page_data.difficulty_page
);
export const attack_modifiers_page: Writable<AttackModifiersPage> = writable(
  default_page_data.attack_modifiers_page
);
export const choose_scenario_page: Writable<ChooseScenarioPage> = writable(
  default_page_data.choose_scenario_page
);
export const treasure_index_page: Writable<TreasureIndexPage> = writable(
  default_page_data.treasure_index_page
);
export const add_monsters_page: Writable<AddMonstersPage> = writable(
  default_page_data.add_monsters_page
);
export const end_scenario_page: Writable<EndScenarioPage> = writable(
  default_page_data.end_scenario_page
);
export const unlock_classes_page: Writable<UnlockClassesPage> = writable(
  default_page_data.unlock_classes_page
);
export const settings_page: Writable<SettingsPage> = writable(
  default_page_data.settings_page
);
export const help_page: Writable<HelpPage> = writable(default_page_data.help_page);
export const books: Writable<BookInfo[]> = writable(default_page_data.books);
export const scenario_pages_page: Writable<ScenarioPagesPage> = writable(
  default_page_data.scenario_pages_page
);
export const section_page: Writable<SectionPage> = writable(
  default_page_data.section_page
);
export const edit_page_sections_page: Writable<EditPageSectionsPage> = writable(
  default_page_data.edit_page_sections_page
);
export const edit_info_page: Writable<EditInfoPage> = writable(
  default_page_data.edit_info_page
);
export const edit_scenario_page: Writable<EditScenarioPage> = writable(
  default_page_data.edit_scenario_page
);
export const campaign_sheet_page: Writable<CampaignSheetPage> = writable(
  default_page_data.campaign_sheet_page
);
export const buildings_page: Writable<BuildingsPage> = writable(
  default_page_data.buildings_page
);
export const loot_cards_page: Writable<LootCardsPage> = writable(
  default_page_data.loot_cards_page
);
export const unlock_buildings_page: Writable<UnlockBuildingsPage> = writable(
  default_page_data.unlock_buildings_page
);
export const character_ability_page: Writable<CharacterAbilityPage> = writable(
  default_page_data.character_ability_page
);

export const browse_monster: Writable<BrowseMobDetail> = writable(
  default_page_data.browse_monster
);
export const browse_boss: Writable<BrowseMobDetail> = writable(
  default_page_data.browse_boss
);
export const browse_simple: Writable<BrowseSimpleDetail> = writable(
  default_page_data.browse_simple
);
export const browse_scenario: Writable<BrowseScenarioDetail> = writable(
  default_page_data.browse_scenario
);
export const browse_ability_card: Writable<BrowseAbilityCardDetail> = writable(
  default_page_data.browse_ability_card
);
