import type { Action } from './stats.js';
import type { PlayerId } from './utility.js';

export enum JournalKind {
  NONE = 0,
  // All things that can be done while visiting gloomhaven.
  GLOOMHAVEN,
  // All things to set up before round 1 of playing a scenario.
  TRAVEL,
  // All things while playing and the conclusion.
  SCENARIO,
}

export interface JournalV2 {
  journal_id: number;
  journal_kind: JournalKind;
  create_time: Date;
  update_time: Date;
  actions: Action[];
}

export function find_completed_id(journals: JournalV2[]) {
  for (let journal_id = journals.length - 1; journal_id >= 0; journal_id--) {
    const j = journals[journal_id];
    if (
      j.journal_kind === JournalKind.SCENARIO &&
      j.actions.some((a) => a[0] === 'complete_scenario')
    )
      return journal_id;
  }
  return -1;
}

export function find_first_completed_id(journals: JournalV2[], scenario_id: number) {
  for (let journal_id = 0; journal_id < journals.length; journal_id++) {
    const j = journals[journal_id];
    if (
      j.journal_kind === JournalKind.SCENARIO &&
      j.actions.some(
        (a) =>
          a[0] === 'complete_scenario' &&
          a[2] === 'success' &&
          a[3] !== 'casual' &&
          parseInt(a[1]) === scenario_id
      )
    )
      return journal_id;
  }
  return -1;
}

export function find_journal_id(journals: JournalV2[], journal_kind: JournalKind) {
  for (let journal_id = journals.length - 1; journal_id >= 0; journal_id--) {
    const j = journals[journal_id];
    if (
      j.journal_kind === JournalKind.SCENARIO &&
      j.actions.some((a) => a[0] === 'complete_scenario')
    )
      return -1;
    if (j.journal_kind === journal_kind) return journal_id;
  }
  return -1;
}

function same_action(action: any[], prefix: any[]) {
  if (prefix == null || action == null) return false;
  if (prefix.length > action.length) return false;
  for (var i = 0; i < prefix.length; ++i) {
    if (prefix[i] !== action[i + (action[0] === 'context' ? 3 : 0)]) return false;
  }
  return true;
}

export function find_journal_id_for_prefix(journals: JournalV2[], prefix: Action) {
  for (let journal_id = journals.length - 1; journal_id >= 0; journal_id--) {
    const j = journals[journal_id];
    if (
      j.journal_kind === JournalKind.SCENARIO &&
      j.actions.some((a) => a[0] === 'complete_scenario')
    )
      return -1;
    if (j.actions.some((a) => same_action(a, prefix))) return journal_id;
  }
  return -1;
}

export function get_party<Type extends PlayerId>(journal: JournalV2, players: Type[]) {
  if (!journal || !players) return players;
  const party = journal.actions.find((a) => a[0] === 'party');
  if (!party) return players;
  const player_ids = party.slice(1).map((a) => parseInt(a));
  return players.filter((p) => player_ids.includes(p.player_id));
}

export function find_player_stat<Type extends PlayerId>(
  journal: JournalV2,
  player: Type,
  stat: string
) {
  const action = journal.actions.find(
    (a) => a[0] === stat && parseInt(a[3]) === player.player_id
  );
  return action ? parseInt(action[1]) : 0;
}
